import { mapGetters } from 'vuex';

const parseEvent = require('./parseEvent').default;

export default {
  data() {
    return {
      iframeHeight: 739,
      iframe: {
        src: '',
        loaded: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      langId: 'lang/id',
      balance: 'cabinet/balance/balance',
      tags: 'cabinet/tags/tags',
    }),
  },
  mounted() {
    this.iframe.src = `https://login.ria.com/ria-profile/2/${this.langId}`;
    window.addEventListener('message', this.receiveMessage, false);
  },
  methods: {
    receiveMessage(event) {
      const [type, data] = parseEvent(event);
      switch (type) {
        case 'height': {
          this.iframeHeight = Number(data.height + 10);
          break;
        }
      }
    },
    load() {
      this.iframe.loaded = true;
    },
  },
};
